// @flow
import * as SentrySDK from "@sentry/react";

const Sentry = {
  init(): void {
    if (process.env.NODE_ENV === "production") {
      SentrySDK.init({
        dsn: "https://641deac0cc910659e8b0b71fde10819a@o4507675136032768.ingest.us.sentry.io/4507675138523136",
        integrations: [SentrySDK.browserTracingIntegration(), SentrySDK.replayIntegration()],
        tracesSampleRate: 0.2,
        tracePropagationTargets: ["localhost", /^https:\/\/dryculator\.io\/api/],
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
      });
    }
  },
  captureException(error: Error): void {
    if (process.env.NODE_ENV === "production") {
      SentrySDK.captureException(error);
    } else {
      console.error(error);
    }
  },
};

export default Sentry;
