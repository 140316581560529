// @flow
import * as React from "react";

function Footer(): React.Node {
  return (
    <footer className="bg-light py-4">
      <div className="container">
        <div className="row">
          <div className="col-md-6 text-center text-md-start">
            <p className="mb-0">© 2024 Dryculator.io. Усі права захищені.</p>
          </div>
          <div className="col-md-6 text-center text-md-end">
            <a href="mailto:support@dryculator.io">support@dryculator.io</a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
