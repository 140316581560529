// @flow
import * as React from "react";

import { Route, Routes } from "react-router-dom";

import Loading from "views/screens/Loading";

const NotFound = React.lazy(() => import("views/pages/NotFound"));
const Settings = React.lazy(() => import("views/pages/Settings"));
const Dashboard = React.lazy(() => import("views/pages/Dashboard"));

const OrderRoutes = React.lazy(() => import("views/pages/orders/routes"));
const CustomerRoutes = React.lazy(() => import("views/pages/customers/routes"));

function Main(): React.Node {
  return (
    <main className="pt-4 pb-3">
      <React.Suspense
        fallback={
          <div className="container">
            <Loading />
          </div>
        }>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/orders/*" element={<OrderRoutes />} />
          <Route path="/customers/*" element={<CustomerRoutes />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </React.Suspense>
    </main>
  );
}

export default Main;
