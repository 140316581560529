// @flow
import * as React from "react";

import { Link, NavLink } from "react-router-dom";

function Header(): React.Node {
  React.useEffect(() => {
    const navbar = document.getElementById("navbar");
    const navLinks = document.querySelectorAll("a.nav-link");

    const collapse = new window.bootstrap.Collapse(navbar, {
      toggle: false,
    });

    for (const element of navLinks) {
      element.addEventListener("click", () => collapse.hide());
    }

    return () => {
      for (const element of navLinks) {
        element.removeEventListener("click", () => collapse.hide());
      }
    };
  }, []);

  return (
    <header className="navbar navbar-expand-lg sticky-top bg-dark" data-bs-theme="dark">
      <nav className="container">
        <Link to="/" className="navbar-brand">
          Dryculator
        </Link>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbar"
          aria-controls="navbar"
          aria-expanded="false"
          aria-label="Toggle navigation">
          <span className="navbar-toggler-icon" />
        </button>

        <div className="collapse navbar-collapse" id="navbar">
          <ul className="navbar-nav">
            <li className="nav-item">
              <NavLink to="/" className="nav-link">
                Дашборд
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/orders" className="nav-link">
                Замовлення
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/customers" className="nav-link">
                Замовники
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/settings" className="nav-link">
                Налаштування
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
}

export default Header;
