// @flow
import * as React from "react";

import { Button, Result } from "antd";

function ApplicationError(): React.Node {
  return (
    <Result
      status="error"
      title="Помилка додатку"
      subTitle="Вибачте, сталася непередбачена помилка в додатку. Ми рекомендуємо перезавантажити сторінку."
      extra={
        <React.Fragment>
          <Button type="primary" onClick={() => window.location.reload()}>
            Перезавантажити
          </Button>
          <Button href="/">На головну</Button>
        </React.Fragment>
      }
    />
  );
}

export default ApplicationError;
