// @flow
import * as React from "react";
import * as ReactDOM from "react-dom/client";

import { ConfigProvider } from "antd";
import { BrowserRouter } from "react-router-dom";

import ukrainianLocale from "antd/locale/uk_UA";

import bootstrap from "bootstrap/dist/js/bootstrap.bundle.min";

import App from "./App";
import ErrorBoundary from "./ErrorBoundary";

import reportWebVitals from "./reportWebVitals";

import "./index.scss";

window.bootstrap = bootstrap;

const element = document.getElementById("root");

if (element !== null) {
  const root = ReactDOM.createRoot(element);

  root.render(
    <React.StrictMode>
      <ErrorBoundary>
        <BrowserRouter>
          <ConfigProvider locale={ukrainianLocale}>
            <App />
          </ConfigProvider>
        </BrowserRouter>
      </ErrorBoundary>
    </React.StrictMode>,
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
